// Do not edit this file, it is automatically generated
const locales = {
  "en": {
    "translation": {
      "back": "Back",
      "remove": "remove",
      "moreInformation": "more information",
      "close": "Close",
      "dataGrid": {
        "a11yTextMessage": "{{value}}. You are on row {{rowIndex}}, column {{columnIndex}}. Disregard the following information:"
      },
      "uploader": {
        "label": "Drag and drop or choose files from your computer",
        "overlay": {
          "title": "Drag and drop",
          "subtitle": "Your files here"
        },
        "form": {
          "dragAndDrop": "Drag and drop",
          "or": "or",
          "chooseFiles": "Choose files",
          "from": "From your computer"
        },
        "errors": {
          "file_size": "exceeds the maximum {{size}} file size.",
          "file_type": "{{type}} is incorrect file type."
        },
        "status": {
          "idle": "idle",
          "processing": "processing",
          "uploading": "uploading",
          "success": "success",
          "cancel": "cancel",
          "error": "error"
        }
      },
      "actions": {
        "delete": "Delete",
        "apply": "Apply",
        "cancel": "Cancel",
        "confirm": "Save",
        "decline": "Decline"
      },
      "datePicker": {
        "calendar_header_format": "MMMM YYYY",
        "confirmation_format": "MMMM DD, YYYY",
        "month": "Month",
        "year": "Year"
      },
      "daterange": {
        "last_7_days": "Last 7 Days",
        "last_30_days": "Last 30 Days",
        "this_month": "This Month",
        "last_month": "Last Month",
        "start_date": "Start Date",
        "end_date": "End Date"
      },
      "dropdownMenu": {
        "isExternal": "{{link}} opens in a new tab."
      },
      "comment": {
        "delete": "Delete comment"
      },
      "pagination": {
        "intro": "Pagination, currently on page {{currentPage}}",
        "page": "Page {{pageNumber}}",
        "ellipsis": "More pages",
        "prev": "Previous page",
        "next": "Next page"
      },
      "formElement": {
        "optional": "(optional)",
        "required": "(required)",
        "aria_info_circle": "Information"
      },
      "prevNextArrowButton": {
        "arrowup": "up",
        "arrowdown": "down",
        "escape": "escape"
      },
      "collapsible": {
        "ariaText": "collapsible section"
      },
      "sortable": {
        "aria_description": "Draggable item. Press space to lift.",
        "aria_lift": "You have lifted an item in position {{source}}. Use the arrow keys to move, space to drop, and escape to cancel.",
        "aria_moving": "You have moved the item from position {{source}} to position {{destination}}.",
        "aria_moving_outside": "You are currently not dragging over a droppable area.",
        "aria_cancelled": "Movement cancelled. The item has returned to its starting position of {{source}}.",
        "aria_dropped": "You have dropped the item. It has moved from position {{source}} to {{destination}}.",
        "aria_dropped_outside": "The item has been dropped while not over the list. The item has returned to its starting position of {{source}}.",
        "aria_remove": "Remove"
      },
      "progressAccordion": {
        "active": "active",
        "no_response": "None"
      },
      "collapsibleText": {
        "more": "Show more",
        "less": "Show less",
        "more_about": "show more about {{topic}}",
        "less_about": "show less about {{topic}}"
      },
      "listBox": {
        "trigger": {
          "placeholder": "Select..."
        },
        "filter": {
          "a11y_text": "Filter",
          "no_results_message": "Your search result did not match any options.",
          "placeholder": "Filter..."
        }
      },
      "listBoxBrowser": {
        "explore": "Explore more options",
        "selected": "selected options",
        "selectOne": "Select one or more items..."
      },
      "actionBar": {
        "columns_arrangement": {
          "hide_all": "Hide all",
          "show_all": "Show all",
          "label": "Arrange columns",
          "singular_label": "1 column hidden",
          "plural_label": "{{numberOfHiddenColumn}} columns hidden"
        },
        "no_results": "No results",
        "filter": {
          "no_filters_applied": "No filters applied to this view",
          "label": "Filter",
          "singular_label": "1 filter applied",
          "plural_label": "{{numberOfFilters}} filters applied",
          "add_filter": "Add a field to filter by",
          "and": "and",
          "or": "or",
          "where": "Where",
          "rules": {
            "is": "is",
            "is_not": "is not",
            "contains": "contains",
            "does_not_contain": "does not contain",
            "is_blank": "is blank",
            "is_not_blank": "is not blank",
            "equals": "equals",
            "not_equal_to": "not equal to",
            "greater_than": ">",
            "less_than": "<",
            "greater_than_or_equal_to": "≥",
            "less_than_or_equal_to": "≤",
            "is_empty": "is empty",
            "is_not_empty": "is not empty",
            "is_before": "is before",
            "is_after": "is after",
            "true": "true",
            "false": "false"
          }
        },
        "search_placeholder": "Filter...",
        "sort": {
          "no_sorts_applied": "No sorts applied to this view",
          "label": "Sort",
          "singular_label": "1 column sorted",
          "plural_label": "{{numberOfFields}} columns sorted",
          "add_field": "Add a field to sort by",
          "sort_by": "Sort by",
          "then_by": "then by",
          "rules": {
            "ascending": {
              "boolean": "Sort ascending (true → false)",
              "number": "Sort ascending (1 → 9)",
              "text": "Sort ascending (A → Z)",
              "date": "Sort ascending (1 → 9)"
            },
            "descending": {
              "boolean": "Sort descending (false → true)",
              "number": "Sort descending (9 → 1)",
              "text": "Sort descending (Z → A)",
              "date": "Sort descending (9 → 1)"
            }
          }
        }
      }
    }
  }
};
export default locales;