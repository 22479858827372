// Do not edit this file, it is automatically generated
const locales = {
  "ja": {
    "translation": {
      "back": "戻る",
      "remove": "削除",
      "moreInformation": "詳しい情報",
      "close": "閉じる",
      "dataGrid": {
        "a11yTextMessage": "{{value}}. 行 {{rowIndex}}、列 {{columnIndex}} の位置にいます。次の情報は無視してください。"
      },
      "uploader": {
        "label": "ドラッグ アンド ドロップするか、お手元のコンピュータからファイルを選択する",
        "overlay": {
          "title": "ドラッグ アンド ドロップ",
          "subtitle": "ファイルをここに"
        },
        "form": {
          "dragAndDrop": "ドラッグ アンド ドロップ",
          "or": "または",
          "chooseFiles": "コンピューターからファイルを選択",
          "from": "コンピューターから"
        },
        "errors": {
          "file_size": "ファイル サイズの最大である {{size}} を超過しています。",
          "file_type": "{{type}} は誤ったファイルの種類です。"
        },
        "status": {
          "idle": "アイドル状態",
          "processing": "処理中",
          "uploading": "アップロード中",
          "success": "成功",
          "cancel": "キャンセル",
          "error": "エラー"
        }
      },
      "actions": {
        "delete": "削除",
        "apply": "適用",
        "cancel": "キャンセル",
        "confirm": "保存",
        "decline": "辞退"
      },
      "datePicker": {
        "calendar_header_format": "YYYY 年 M 月",
        "confirmation_format": "YYYY 年 M 月 D 日",
        "month": "月",
        "year": "年"
      },
      "daterange": {
        "last_7_days": "過去 7 日間",
        "last_30_days": "過去 30 日間",
        "this_month": "今月",
        "last_month": "先月",
        "start_date": "開始日",
        "end_date": "終了日"
      },
      "dropdownMenu": {
        "isExternal": "{{link}} が新しいタブで開きます。"
      },
      "comment": {
        "delete": "コメントを削除"
      },
      "pagination": {
        "intro": "改ページ位置の自動修正、現在は {{currentPage}} ページ",
        "page": "{{pageNumber}} ページ",
        "ellipsis": "その他のページ",
        "prev": "前ページ",
        "next": "次ページ"
      },
      "formElement": {
        "optional": "(省略可能)",
        "required": "(必須)",
        "aria_info_circle": "情報"
      },
      "prevNextArrowButton": {
        "arrowup": "上",
        "arrowdown": "下",
        "escape": "エスケープ"
      },
      "collapsible": {
        "ariaText": "縮小可能セクション"
      },
      "sortable": {
        "aria_description": "ドラッグ可能な項目。スペースを押して取り除きます。",
        "aria_lift": "{{source}}のポジションにある項目を 1 つ取り除きました。移動するには矢印、ドロップするにはスペース、そしてキャンセルするにはエスケープ キーを使用します。",
        "aria_moving": "{{source}}のポジションから{{destination}}のポジションまで項目を移動しました。",
        "aria_moving_outside": "現在ドラッグしているのは、ドラッグ可能な領域ではありません。",
        "aria_cancelled": "移動がキャンセルされました。項目は、{{source}}の出発点に戻りました。",
        "aria_dropped": "項目をドロップしました。{{source}}のポジションから{{destination}}のポジションまで項目が移動しました。",
        "aria_dropped_outside": "一覧にない項目がドロップされました。項目は、{{source}}の出発点に戻りました。",
        "aria_remove": "削除"
      },
      "progressAccordion": {
        "active": "アクティブ",
        "no_response": "なし"
      },
      "collapsibleText": {
        "more": "詳しく表示",
        "less": "少なく表示",
        "more_about": "{{topic}}について詳しく表示",
        "less_about": "{{topic}}について少なく表示"
      },
      "listBox": {
        "trigger": {
          "placeholder": "選択..."
        },
        "filter": {
          "a11y_text": "フィルター",
          "no_results_message": "検索結果はどのオプションにも一致しませんでした。",
          "placeholder": "フィルター..."
        }
      },
      "listBoxBrowser": {
        "explore": "より多くのオプションを探す",
        "selected": "選択されたオプション",
        "selectOne": "1 つ以上の項目を選択..."
      }
    }
  }
};
export default locales;