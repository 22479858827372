// Do not edit this file, it is automatically generated
const locales = {
  "pt": {
    "translation": {
      "back": "Voltar",
      "remove": "remover",
      "moreInformation": "mais informações",
      "close": "Fechar",
      "dataGrid": {
        "a11yTextMessage": "{{value}}. Você está na linha {{rowIndex}}, coluna {{columnIndex}}. Ignorar as seguintes informações:"
      },
      "uploader": {
        "label": "Arraste e solte ou selecione arquivos no seu computador",
        "overlay": {
          "title": "Arraste e solte",
          "subtitle": "Seus arquivos aqui"
        },
        "form": {
          "dragAndDrop": "Arraste e solte",
          "or": "ou",
          "chooseFiles": "Selecionar arquivos",
          "from": "Do seu computador"
        },
        "errors": {
          "file_size": "excede o tamanho máximo {{size}} do arquivo.",
          "file_type": "{{type}} é um tipo de arquivo incorreto."
        },
        "status": {
          "idle": "ocioso",
          "processing": "processando",
          "uploading": "fazendo upload",
          "success": "sucesso",
          "cancel": "cancelar",
          "error": "erro"
        }
      },
      "actions": {
        "delete": "Excluir",
        "apply": "Aplicar",
        "cancel": "Cancelar",
        "confirm": "Salvar",
        "decline": "Recusar"
      },
      "datePicker": {
        "calendar_header_format": "MMMM YYYY",
        "confirmation_format": "ll",
        "month": "Mês",
        "year": "Ano"
      },
      "daterange": {
        "last_7_days": "Últimos 7 Dias",
        "last_30_days": "Últimos 30 Dias",
        "this_month": "Este Mês",
        "last_month": "Mês Passado",
        "start_date": "Data de Início",
        "end_date": "Data de Término"
      },
      "dropdownMenu": {
        "isExternal": "{{link}} abre em uma nova guia."
      },
      "comment": {
        "delete": "Excluir comentário"
      },
      "pagination": {
        "intro": "Paginação, atualmente na página {{currentPage}}",
        "page": "Página {{pageNumber}}",
        "ellipsis": "Mais páginas",
        "prev": "Página anterior",
        "next": "Próxima página"
      },
      "formElement": {
        "optional": "(opcional)",
        "required": "(obrigatório)",
        "aria_info_circle": "Informação"
      },
      "prevNextArrowButton": {
        "arrowup": "para cima",
        "arrowdown": "para baixo",
        "escape": "ESC"
      },
      "collapsible": {
        "ariaText": "seção recolhível"
      },
      "sortable": {
        "aria_description": "Item arrastável. Pressione espaço para levantar.",
        "aria_lift": "Você levantou um item em posição {{source}}. Use as teclas de seta para mover, espaço para soltar e esc para cancelar.",
        "aria_moving": "Você moveu o item da posição {{source}} para a posição {{destination}}.",
        "aria_moving_outside": "Você atualmente não está arrastando sobr euma área onde é possível soltar.",
        "aria_cancelled": "Movimento cancelado. O item voltou para sua posição inicial de {{source}}.",
        "aria_dropped": "Você soltou o item. Ele foi movido da posição {{source}} para a posição {{destination}}.",
        "aria_dropped_outside": "O item foi solto enquanto não estava sobre a lista. O item voltou para sua posição inicial de {{source}}.",
        "aria_remove": "Remover"
      },
      "progressAccordion": {
        "active": "ativo(a)",
        "no_response": "Nenhum"
      },
      "collapsibleText": {
        "more": "Mostrar mais",
        "less": "Mostrar menos",
        "more_about": "mostrar mais sobre {{topic}}",
        "less_about": "mostrar menos sobre {{topic}}"
      },
      "listBox": {
        "trigger": {
          "placeholder": "Selecione..."
        },
        "filter": {
          "a11y_text": "Filtrar",
          "no_results_message": "O resultado da sua pesquisa não correspondeu a nenhuma opção.",
          "placeholder": "Filtrar..."
        }
      },
      "listBoxBrowser": {
        "explore": "Explorar mais opções",
        "selected": "opções selecionadas",
        "selectOne": "Selecione um ou mais itens..."
      }
    }
  }
};
export default locales;