const Kinds = {
  DEFAULT: "default",
  PRIMARY: "primary",
  SECONDARY: "secondary",
  DESTRUCTIVE: "destructive",
  FLAT: "flat",
  MINOR: "minor",
  LINK: "link",
};
Kinds.ALL = Object.values(Kinds);

export default Kinds;
