import React from "react";

function SvgEllipsis({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        width: ${props.size};
        height: ${props.size};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M5 10a2 2 0 11-3.999.001A2 2 0 015 10zM12 10a2 2 0 11-3.999.001A2 2 0 0112 10zM17 12a2 2 0 10.001-3.999A2 2 0 0017 12z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgEllipsis;
