import React from "react";

function SvgAdd({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        width: ${props.size};
        height: ${props.size};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path d="M11 4H9v5H4v2h5v5h2v-5h5V9h-5V4z" fill="currentColor" />
    </svg>
  );
}

export default SvgAdd;
