import React from "react";

function SvgArrowDown({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        width: ${props.size};
        height: ${props.size};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M5.41 6.5L10 11.136 14.59 6.5 16 7.927l-4.933 4.993a1.5 1.5 0 01-2.134 0L4 7.927 5.41 6.5z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgArrowDown;
