// Do not edit this file, it is automatically generated
const locales = {
  "es": {
    "translation": {
      "back": "Atrás",
      "remove": "quitar",
      "moreInformation": "más información",
      "close": "Cerrar",
      "dataGrid": {
        "a11yTextMessage": "{{value}}. Está en la fila {{rowIndex}}, columna {{columnIndex}}. Haga caso omiso de la siguiente información:"
      },
      "uploader": {
        "label": "Arrastre y suelte o elija archivos de su computadora",
        "overlay": {
          "title": "Arrastrar y soltar",
          "subtitle": "Sus archivos aquí"
        },
        "form": {
          "dragAndDrop": "Arrastrar y soltar",
          "or": "o",
          "chooseFiles": "Elija los archivos",
          "from": "De su computadora"
        },
        "errors": {
          "file_size": "excede el tamaño máximo del archivo de {{size}}.",
          "file_type": "{{type}} es el tipo de archivo incorrecto."
        },
        "status": {
          "idle": "ocioso",
          "processing": "en proceso...",
          "uploading": "cargando...",
          "success": "éxito",
          "cancel": "cancelar",
          "error": "error"
        }
      },
      "actions": {
        "delete": "Eliminar",
        "apply": "Aplicar",
        "cancel": "Cancelar",
        "confirm": "Guardar",
        "decline": "Declinar"
      },
      "datePicker": {
        "calendar_header_format": "MMMM YYYY",
        "confirmation_format": "ll",
        "month": "Mes",
        "year": "Año"
      },
      "daterange": {
        "last_7_days": "Los últimos 7 días",
        "last_30_days": "Los últimos 30 días",
        "this_month": "Este mes",
        "last_month": "El mes pasado",
        "start_date": "Fecha de inicio",
        "end_date": "Fecha de finalización"
      },
      "dropdownMenu": {
        "isExternal": "{{link}} se abre en una nueva ficha."
      },
      "comment": {
        "delete": "Eliminar comentario"
      },
      "pagination": {
        "intro": "Paginación, actualmente en la página {{currentPage}}",
        "page": "Página {{pageNumber}}",
        "ellipsis": "Más páginas",
        "prev": "Página anterior",
        "next": "Siguiente página"
      },
      "formElement": {
        "optional": "(opcional)",
        "required": "(requerido)",
        "aria_info_circle": "Información"
      },
      "prevNextArrowButton": {
        "arrowup": "arriba",
        "arrowdown": "abajo",
        "escape": "escape"
      },
      "collapsible": {
        "ariaText": "sección contraíble"
      },
      "sortable": {
        "aria_description": "Elemento arrastrable. Presione la barra espaciadora para levantar.",
        "aria_lift": "Ha levantado un elemento de la posición {{source}}. Utilice las teclas de flecha para moverse, el espacio para soltar y la tecla escape para cancelar.",
        "aria_moving": "Ha movido el elemento de la posición {{source}} a la posición {{destination}}.",
        "aria_moving_outside": "Actualmente no está arrastrando sobre un área donde soltar.",
        "aria_cancelled": "Movimiento cancelado. El elemento ha vuelto a su posición inicial de {{source}}.",
        "aria_dropped": "Ha soltado el elemento. Se ha movido de la posición {{source}} a {{destination}}.",
        "aria_dropped_outside": "El elemento se ha soltado mientras no estaba sobre la lista. El elemento ha vuelto a su posición inicial de {{source}}.",
        "aria_remove": "Quitar"
      },
      "progressAccordion": {
        "active": "activo/a",
        "no_response": "Ninguno"
      },
      "collapsibleText": {
        "more": "Mostrar más",
        "less": "Mostrar menos",
        "more_about": "mostrar más sobre {{topic}}",
        "less_about": "mostrar menos sobre {{topic}}"
      },
      "listBox": {
        "trigger": {
          "placeholder": "Seleccionar..."
        },
        "filter": {
          "a11y_text": "Filtrar",
          "no_results_message": "Su resultado de búsqueda no coincidió con ninguna opción.",
          "placeholder": "Filtrar..."
        }
      },
      "listBoxBrowser": {
        "explore": "Explorar más opciones",
        "selected": "opciones seleccionadas",
        "selectOne": "Seleccionar uno o más elementos..."
      }
    }
  }
};
export default locales;