// Do not edit this file, it is automatically generated
const locales = {
  "zh": {
    "translation": {
      "back": "返回",
      "remove": "移除",
      "moreInformation": "更多信息",
      "close": "关闭",
      "dataGrid": {
        "a11yTextMessage": "{{value}}。您在第 {{rowIndex}} 行，第 {{columnIndex}} 列。忽略以下信息："
      },
      "uploader": {
        "label": "拖放或从您的电脑中选择文件",
        "overlay": {
          "title": "拖放",
          "subtitle": "您的文件到此"
        },
        "form": {
          "dragAndDrop": "拖放",
          "or": "或",
          "chooseFiles": "选择文件",
          "from": "从您的电脑"
        },
        "errors": {
          "file_size": "超出最大 {{size}} 的文件限制。",
          "file_type": "{{type}}是不正确的文件类型。"
        },
        "status": {
          "idle": "闲置",
          "processing": "正在处理",
          "uploading": "上传中",
          "success": "成功",
          "cancel": "取消",
          "error": "错误"
        }
      },
      "actions": {
        "delete": "删除",
        "apply": "应用",
        "cancel": "取消",
        "confirm": "保存",
        "decline": "拒绝"
      },
      "datePicker": {
        "calendar_header_format": "YYYY 年 M 月",
        "confirmation_format": "YYYY 年 M 月 D 日",
        "month": "月",
        "year": "年"
      },
      "daterange": {
        "last_7_days": "过去 7 天",
        "last_30_days": "过去 30 天",
        "this_month": "本月",
        "last_month": "上月",
        "start_date": "开始日期",
        "end_date": "结束日期"
      },
      "dropdownMenu": {
        "isExternal": "{{link}} 在新选项卡中打开。"
      },
      "comment": {
        "delete": "删除评论"
      },
      "pagination": {
        "intro": "分页，当前在第 {{currentPage}} 页",
        "page": "页 {{pageNumber}}",
        "ellipsis": "更多页面",
        "prev": "上一页",
        "next": "下一页"
      },
      "formElement": {
        "optional": "（可选）",
        "required": "（必须）",
        "aria_info_circle": "信息"
      },
      "prevNextArrowButton": {
        "arrowup": "上",
        "arrowdown": "下",
        "escape": "Esc 键"
      },
      "collapsible": {
        "ariaText": "可折叠部分"
      },
      "sortable": {
        "aria_description": "可拖动项目。按空格键提起。",
        "aria_lift": "您已将一个项目提起到位 {{source}}。使用箭头键移动，使用空格键放下，使用 Esc 键取消。",
        "aria_moving": "您已将项目从位置{{source}移动到位置{{destination}。",
        "aria_moving_outside": "您当前不是在可拖放区域上拖动。",
        "aria_cancelled": "已取消移动。项目已返回到其{{source}}的起始位置。",
        "aria_dropped": "您已放下该项目。它已从位置{{source}移到{{destination}。",
        "aria_dropped_outside": "该项目不在列表上方时已被放下。项目已返回到其{{source}}的起始位置。",
        "aria_remove": "移除"
      },
      "progressAccordion": {
        "active": "活动的",
        "no_response": "无"
      },
      "collapsibleText": {
        "more": "显示更多",
        "less": "显示更少",
        "more_about": "显示关于{{topic}}的更多信息",
        "less_about": "显示关于{{topic}}的更少信息"
      },
      "listBox": {
        "trigger": {
          "placeholder": "选择…"
        },
        "filter": {
          "a11y_text": "筛选",
          "no_results_message": "您的搜索结果没有匹配任何选项。",
          "placeholder": "筛选…"
        }
      },
      "listBoxBrowser": {
        "explore": "探索更多选项",
        "selected": "选定选项",
        "selectOne": "选择一项或多项..."
      }
    }
  }
};
export default locales;