// Do not edit this file, it is automatically generated by @paprika/build-translations
  const locales = {
  "it": {
    "translation": {
      "formElement": {
        "optional": "(facoltativo)",
        "required": "(obbligatorio)"
      },
      "calendar": {
        "header_format": "MMMM YYYY",
        "month": "Mese",
        "year": "Anno"
      },
      "actions": {
        "apply": "Applica",
        "cancel": "Cancella"
      }
    }
  }
};
  export default locales;