// Do not edit this file, it is automatically generated
const locales = {
  "fr": {
    "translation": {
      "back": "Retour",
      "remove": "supprimer",
      "moreInformation": "plus d'informations",
      "close": "Fermer",
      "dataGrid": {
        "a11yTextMessage": "{{value}}. Vous êtes désormais dans la ligne {{rowIndex}}, colonne {{columnIndex}}. Ignorez les informations suivantes :"
      },
      "uploader": {
        "label": "Glissez-déplacez ou choisissez des fichiers dans votre ordinateur",
        "overlay": {
          "title": "Glisser-déposer",
          "subtitle": "Vos fichiers ici"
        },
        "form": {
          "dragAndDrop": "Glisser-déposer",
          "or": "ou",
          "chooseFiles": "Sélectionner des fichiers",
          "from": "Depuis votre ordinateur"
        },
        "errors": {
          "file_size": "dépasse la taille du fichier {{size}} maximale.",
          "file_type": "{{type}} est un type de fichier incorrect."
        },
        "status": {
          "idle": "inactif",
          "processing": "traitement",
          "uploading": "chargement",
          "success": "réussite",
          "cancel": "annuler",
          "error": "erreur"
        }
      },
      "actions": {
        "delete": "Supprimer",
        "apply": "Appliquer",
        "cancel": "Annuler",
        "confirm": "Enregistrer",
        "decline": "Refuser"
      },
      "datePicker": {
        "calendar_header_format": "MMMM YYYY",
        "confirmation_format": "D MMMM YYYY",
        "month": "Mois",
        "year": "Année"
      },
      "daterange": {
        "last_7_days": "7 derniers jours",
        "last_30_days": "30 derniers jours",
        "this_month": "Ce mois",
        "last_month": "Le mois dernier",
        "start_date": "Date de début",
        "end_date": "Date de fin"
      },
      "dropdownMenu": {
        "isExternal": "{{link}} s'ouvre dans un nouvel onglet."
      },
      "comment": {
        "delete": "Supprimer le commentaire"
      },
      "pagination": {
        "intro": "Pagination, actuellement à la page {{currentPage}}",
        "page": "Page {{pageNumber}}",
        "ellipsis": "Plus de pages",
        "prev": "Page précédente",
        "next": "Page suivante"
      },
      "formElement": {
        "optional": "(facultatif)",
        "required": "(requis)",
        "aria_info_circle": "Informations"
      },
      "prevNextArrowButton": {
        "arrowup": "haut",
        "arrowdown": "bas",
        "escape": "Échap"
      },
      "collapsible": {
        "ariaText": "section compressible"
      },
      "sortable": {
        "aria_description": "Élément déplaçable. Appuyez sur la touche espace pour lever.",
        "aria_lift": "Vous avez élevé un élément dans la position {{source}}. Utilisez les touches de direction pour déplacer, la touche Espace pour déposer et Échap pour annuler.",
        "aria_moving": "Vous avez déplacé l'élément de la position {{source}} vers la position {{destination}}.",
        "aria_moving_outside": "Vous n'êtes pas en train de faire glisser un élément sur une zone déposable.",
        "aria_cancelled": "Mouvement annulé. L'élément est retourné à sa position initiale de {{source}}.",
        "aria_dropped": "Vous avez déposé l'élément. Il est déplacé de la position {{source}} vers {{destination}}.",
        "aria_dropped_outside": "L'élément a été déposé alors qu'il n'était au-dessus de la liste. L'élément est retourné à sa position initiale de {{source}}.",
        "aria_remove": "Supprimer"
      },
      "progressAccordion": {
        "active": "actif(ve)",
        "no_response": "Aucun"
      },
      "collapsibleText": {
        "more": "Afficher plus",
        "less": "Afficher moins",
        "more_about": "Afficher plus sur {{topic}}",
        "less_about": "Afficher moins sur {{topic}}"
      },
      "listBox": {
        "trigger": {
          "placeholder": "Sélectionner..."
        },
        "filter": {
          "a11y_text": "Filtrer",
          "no_results_message": "Vos résultats de recherche ne correspondaient à aucune option.",
          "placeholder": "Filtrer..."
        }
      },
      "listBoxBrowser": {
        "explore": "Découvrir d'autres options",
        "selected": "options sélectionnées",
        "selectOne": "Sélectionner un ou plusieurs éléments..."
      }
    }
  }
};
export default locales;