// Do not edit this file, it is automatically generated
const locales = {
  "pl": {
    "translation": {
      "back": "powrót",
      "remove": "usuń",
      "moreInformation": "więcej informacji",
      "close": "Zamknij",
      "dataGrid": {
        "a11yTextMessage": "{{value}}. Jesteś teraz w wierszu {{rowIndex}}, kolumnie {{columnIndex}}. Nie zwracaj uwagi na następujące informacje:"
      },
      "uploader": {
        "label": "Przeciągnij i upuść lub wybierz pliki z komputera",
        "overlay": {
          "title": "Przeciągnij i upuść",
          "subtitle": "Twoje pliki"
        },
        "form": {
          "dragAndDrop": "Przeciągnij i upuść",
          "or": "lub",
          "chooseFiles": "Wybierz pliki",
          "from": "Z komputera"
        },
        "errors": {
          "file_size": "przekracza maksymalna rozmiar pliku {{size}}.",
          "file_type": "{{type}} jest niepoprawnym typem pliku."
        },
        "status": {
          "idle": "w oczekiwaniu",
          "processing": "przetwarzanie",
          "uploading": "wgrywanie",
          "success": "pomyślnie zakończono",
          "cancel": "anuluj",
          "error": "błąd"
        }
      },
      "actions": {
        "delete": "Usuń",
        "apply": "Zastosuj",
        "cancel": "Anuluj",
        "confirm": "Zapisz",
        "decline": "Odrzuć"
      },
      "datePicker": {
        "calendar_header_format": "MMMM YYYY",
        "confirmation_format": "DD MMMM YYYY",
        "month": "Miesiąc",
        "year": "Rok"
      },
      "daterange": {
        "last_7_days": "Ostanie 7 Dni",
        "last_30_days": "Ostanie 30 Dni",
        "this_month": "Ten miesiąc",
        "last_month": "Poprzedni miesiąc",
        "start_date": "Data Początkowa",
        "end_date": "Data Końcowa"
      },
      "dropdownMenu": {
        "isExternal": "{{link}} otwarty zostanie w nowej zakładce."
      },
      "comment": {
        "delete": "Usuń komentarz"
      },
      "pagination": {
        "intro": "Paginacja, obecnie na stronie {{currentPage}}",
        "page": "Strona {{pageNumber}}",
        "ellipsis": "Więcej stron",
        "prev": "Poprzednia strona",
        "next": "Następna strona"
      },
      "formElement": {
        "optional": "(opcjonalny)",
        "required": "(wymagane)",
        "aria_info_circle": "Informacja"
      },
      "prevNextArrowButton": {
        "arrowup": "w górę",
        "arrowdown": "w dół",
        "escape": "escape"
      },
      "collapsible": {
        "ariaText": "zwiń sekcję"
      },
      "sortable": {
        "aria_description": "Element przeciągany. Naciśnij spację, aby podnieść.",
        "aria_lift": "Podniosłeś przedmiot na pozycję {{source}}. Użyj klawiszy strzałek, aby się poruszać, spacji, aby upuścić i escape, aby anulować.",
        "aria_moving": "Przesunąłeś przedmiot z pozycji {{source}} na pozycję {{destination}}.",
        "aria_moving_outside": "Obecnie nie przeciągasz po obszarze na którym można upuścić.",
        "aria_cancelled": "Ruch anulowany. Element powrócił do pozycji początkowej {{source}}.",
        "aria_dropped": "Upuściłeś przedmiot. Przesunął się z pozycji {{source}} na {{destination}}.",
        "aria_dropped_outside": "Element został upuszczony, ale nie znajduje się na liście. Element powrócił do pozycji początkowej {{source}}.",
        "aria_remove": "Usuń"
      },
      "progressAccordion": {
        "active": "aktywny",
        "no_response": "Żaden"
      },
      "collapsibleText": {
        "more": "Pokaż więcej",
        "less": "Pokaż mniej",
        "more_about": "Pokaż więcej na temat {{topic}}",
        "less_about": "Pokaż mniej na temat {{topic}}"
      },
      "listBox": {
        "trigger": {
          "placeholder": "Wybierz..."
        },
        "filter": {
          "a11y_text": "Filtr",
          "no_results_message": "Twój wynik wyszukiwania nie odpowiadał żadnym opcjom.",
          "placeholder": "Filtr..."
        }
      },
      "listBoxBrowser": {
        "explore": "Przeglądaj więcej opcji",
        "selected": "zaznaczone opcje",
        "selectOne": "Wybierz jeden lub kilka elementów..."
      }
    }
  }
};
export default locales;