// Do not edit this file, it is automatically generated
const locales = {
  "de": {
    "translation": {
      "back": "Zurück",
      "remove": "entfernen",
      "moreInformation": "weitere Informationen",
      "close": "Schließen",
      "dataGrid": {
        "a11yTextMessage": "{{value}}. Sie befinden sich in Reihe {{rowIndex}}, Spalte {{columnIndex}}. Ignorieren Sie die folgenden Informationen:"
      },
      "uploader": {
        "label": "Ziehen und Ablegen oder wählen Sie Dateien von Ihrem Computer",
        "overlay": {
          "title": "Ziehen & Ablegen",
          "subtitle": "Dateien hier"
        },
        "form": {
          "dragAndDrop": "Ziehen & Ablegen",
          "or": "oder",
          "chooseFiles": "Dateien auswählen",
          "from": "Von Ihrem Rechner"
        },
        "errors": {
          "file_size": "überschreitet die maximale Dateigröße von {{size}}.",
          "file_type": "{{type}} ist nicht der richtige Dateityp."
        },
        "status": {
          "idle": "Inaktiv",
          "processing": "Verarbeitung läuft...",
          "uploading": "Daten werden hochgeladen...",
          "success": "Erfolg!",
          "cancel": "Abbrechen",
          "error": "Fehler"
        }
      },
      "actions": {
        "delete": "Löschen",
        "apply": "Anwenden",
        "cancel": "Abbrechen",
        "confirm": "Speichern",
        "decline": "Ablehnen"
      },
      "datePicker": {
        "calendar_header_format": "MMMM YYYY",
        "confirmation_format": "DD. MMMM YYYY",
        "month": "Monat",
        "year": "Jahr"
      },
      "daterange": {
        "last_7_days": "Letzte 7 Tage",
        "last_30_days": "Letzte 30 Tage",
        "this_month": "Diesen Monat",
        "last_month": "Letzten Monat",
        "start_date": "Anfangsdatum",
        "end_date": "Enddatum"
      },
      "dropdownMenu": {
        "isExternal": "{{link}} wird auf einer neuen Registerkarte geöffnet."
      },
      "comment": {
        "delete": "Anmerkung löschen"
      },
      "pagination": {
        "intro": "Seitennummerierung, aktuell auf Seite {{currentPage}}",
        "page": "Seite {{pageNumber}}",
        "ellipsis": "Weitere Seiten",
        "prev": "Vorherige Seite",
        "next": "Nächste Seite"
      },
      "formElement": {
        "optional": "(optional)",
        "required": "(erforderlich)",
        "aria_info_circle": "Informationen"
      },
      "prevNextArrowButton": {
        "arrowup": "Auf",
        "arrowdown": "Ab",
        "escape": "Escape"
      },
      "collapsible": {
        "ariaText": "ausblendbarer Bereich"
      },
      "sortable": {
        "aria_description": "Ziehbares Element. Drücken Sie zum Anheben die Leertaste.",
        "aria_lift": "Sie haben ein Element in Position {{source}} angehoben. Verwenden Sie die Pfeiltasten, um es zu bewegen, die Leertaste, um es fallen zu lassen, und Esc, um Aktion abzubrechen.",
        "aria_moving": "Sie haben das Element von Position {{source}} an Position {{destination}} verschoben.",
        "aria_moving_outside": "Sie ziehen das Element derzeit nicht über einen Bereich, auf dem Sie es ablegen können.",
        "aria_cancelled": "Verschieben abgebrochen. Das Element ist in seine Ausgangsposition von {{source}} zurückgekehrt.",
        "aria_dropped": "Sie haben das Element abgelegt. Das Element wurde von Position {{source}} an Position {{destination}} verschoben.",
        "aria_dropped_outside": "Das Element wurde abgelegt, während es sich nicht über der Liste befand. Das Element ist in seine Ausgangsposition von {{source}} zurückgekehrt.",
        "aria_remove": "Entfernen"
      },
      "progressAccordion": {
        "active": "aktiv",
        "no_response": "Keine"
      },
      "collapsibleText": {
        "more": "Mehr anzeigen",
        "less": "Weniger anzeigen",
        "more_about": "Mehr über {{topic}} anzeigen",
        "less_about": "Weniger über {{topic}} anzeigen"
      },
      "listBox": {
        "trigger": {
          "placeholder": "Auswählen..."
        },
        "filter": {
          "a11y_text": "Filtern",
          "no_results_message": "Ihr Suchergebnis entsprach keiner der Optionen.",
          "placeholder": "Filtern..."
        }
      },
      "listBoxBrowser": {
        "explore": "Weitere Optionen anzeigen",
        "selected": "ausgewählte Optionen",
        "selectOne": "Ein oder mehrere Elemente auswählen..."
      }
    }
  }
};
export default locales;