import React from "react";

function SvgCaretDown({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        width: ${props.size};
        height: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.847 12.608c-.217.25-.525.392-.847.392-.322 0-.63-.142-.847-.392L5.15 8.001a.62.62 0 01-.1-.645A.572.572 0 015.572 7h8.856c.226 0 .431.14.523.356a.62.62 0 01-.1.645l-4.004 4.607z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCaretDown;
