import React from "react";

function SvgLock({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        width: ${props.size};
        height: ${props.size};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.286 8.375V6.38C14.286 3.968 12.362 2 10 2 7.636 2 5.714 3.968 5.714 6.38v1.995C4.77 8.375 4 9.159 4 10.125v6.125c0 .965.769 1.75 1.714 1.75h8.572C15.23 18 16 17.215 16 16.25v-6.125c0-.966-.769-1.75-1.714-1.75zM10 14.5c-.71 0-1.286-.588-1.286-1.313 0-.724.576-1.312 1.286-1.312.71 0 1.286.588 1.286 1.313 0 .724-.576 1.312-1.286 1.312zm2.571-6.125H7.43V6.37c0-1.447 1.153-2.62 2.571-2.62 1.418 0 2.571 1.173 2.571 2.62v2.005z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgLock;
