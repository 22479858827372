import React from "react";

function SvgCheck({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        width: ${props.size};
        height: ${props.size};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 5.52L9.343 16.217l-.054.062a1.315 1.315 0 01-.327.245c-.32.163-.705.173-1.033.026a1.312 1.312 0 01-.34-.228L2 10.974l1.656-1.73 4.656 4.454L16.15 4 18 5.52z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCheck;
